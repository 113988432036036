@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* Scorekeeper.css */

/* Dark snowy background */
.xmas-body {
  background: #1a1a2e; /* Deep navy background */
  color: white;
  font-family: 'Press Start 2P', cursive;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow-y: auto;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
}

.xmas-container {
  position: relative;
  background: rgba(0, 0, 0, 0.8);
  padding: 20px;
  border-radius: 15px;
  text-align: center;
  box-shadow: 0 0 10px #ffffff50;
  z-index: 1;
  width: 100%;
  max-width: 1000px;
}

/* Centered container */

/* Headers with Christmas colors */
.xmas-header {
  color: #e63946; /* Bright red */
  font-size: 2em;
  margin: 10px 0;
  font-family: "Press Start 2P", serif;
  font-weight: 400;
  font-style: normal;
  
}

.xmas-subheader {
  color: #73ba9b; /* Soft icy blue */
  font-size: 1.5em;
  /* margin-bottom: 20px; */
  font-family: "Coda", serif;
  font-weight: 400;
}

/* Retro-style buttons */
.xmas-button {
  background-color: #e63946; /* Bright red */
  color: white;
  font-family: 'Press Start 2P', cursive;
  font-size: 0.8em;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  margin: 10px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.xmas-button:hover {
  background-color: #f77f00; /* Lighter orange-red */
  transform: scale(1.1); /* Slightly enlarges on hover */
}

.xmas-button-play {
  background-color: #e63946; /* Bright red */
  color: white;
  font-family: 'Press Start 2P', cursive;
  font-size: 1.8em;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  margin: 10px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.xmas-button:hover {
  background-color: #f77f00; /* Lighter orange-red */
  transform: scale(1.1); /* Slightly enlarges on hover */
}

/* Table styling */
.xmas-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  color: white;
}

.xmas-table th {
  background: #e63946; /* Bright red */
  color: white;
  padding: 10px;
  font-family: 'Press Start 2P', cursive;
}

.xmas-table td {
  /* background: rgba(255, 255, 255, 0.1); */
  color: white;
  text-align: center;
  padding: 10px;
  border: 1px solid #ffffff30;
}

/* Input styling */
.xmas-input {
  font-family: 'Press Start 2P', cursive;
  padding: 10px;
  background: #ffffff20;
  /* border: 2px solid #e63946; Red border */
  color: white;
  border-radius: 5px;
  width: calc(100% - 22px);
  margin-top: 10px;
}

.snow {
  /* width: 500px; */
  /* height: 100px; */
  border: 1px solid rgba(255, 255, 255, 0.1);
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 50 50' style='enable-background:new 0 0 50 50%3B' xml:space='preserve'%3E%3Cstyle type='text/css'%3E.st1%7Bopacity:0.3%3Bfill:%23FFFFFF%3B%7D.st3%7Bopacity:0.1%3Bfill:%23FFFFFF%3B%7D%3C/style%3E%3Ccircle class='st1' cx='5' cy='8' r='1'/%3E%3Ccircle class='st1' cx='38' cy='3' r='1'/%3E%3Ccircle class='st1' cx='12' cy='4' r='1'/%3E%3Ccircle class='st1' cx='16' cy='16' r='1'/%3E%3Ccircle class='st1' cx='47' cy='46' r='1'/%3E%3Ccircle class='st1' cx='32' cy='10' r='1'/%3E%3Ccircle class='st1' cx='3' cy='46' r='1'/%3E%3Ccircle class='st1' cx='45' cy='13' r='1'/%3E%3Ccircle class='st1' cx='10' cy='28' r='1'/%3E%3Ccircle class='st1' cx='22' cy='35' r='1'/%3E%3Ccircle class='st1' cx='3' cy='21' r='1'/%3E%3Ccircle class='st1' cx='26' cy='20' r='1'/%3E%3Ccircle class='st1' cx='30' cy='45' r='1'/%3E%3Ccircle class='st1' cx='15' cy='45' r='1'/%3E%3Ccircle class='st1' cx='34' cy='36' r='1'/%3E%3Ccircle class='st1' cx='41' cy='32' r='1'/%3E%3C/svg%3E");
  background-position: 0px 0px;
  animation: animatedBackground 10s linear infinite;
}

.snow div {
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 50 50' style='enable-background:new 0 0 50 50%3B' xml:space='preserve'%3E%3Cstyle type='text/css'%3E.st1%7Bopacity:0.7%3Bfill:%23FFFFFF%3B%7D.st3%7Bopacity:0.1%3Bfill:%23FFFFFF%3B%7D%3C/style%3E%3Ccircle class='st3' cx='4' cy='14' r='1'/%3E%3Ccircle class='st3' cx='43' cy='3' r='1'/%3E%3Ccircle class='st3' cx='31' cy='30' r='2'/%3E%3Ccircle class='st3' cx='19' cy='23' r='1'/%3E%3Ccircle class='st3' cx='37' cy='22' r='1'/%3E%3Ccircle class='st3' cx='43' cy='16' r='1'/%3E%3Ccircle class='st3' cx='8' cy='45' r='1'/%3E%3Ccircle class='st3' cx='29' cy='39' r='1'/%3E%3Ccircle class='st3' cx='13' cy='37' r='1'/%3E%3Ccircle class='st3' cx='47' cy='32' r='1'/%3E%3Ccircle class='st3' cx='15' cy='4' r='2'/%3E%3Ccircle class='st3' cx='9' cy='27' r='1'/%3E%3Ccircle class='st3' cx='30' cy='9' r='1'/%3E%3Ccircle class='st3' cx='25' cy='15' r='1'/%3E%3Ccircle class='st3' cx='21' cy='45' r='2'/%3E%3Ccircle class='st3' cx='42' cy='45' r='1'/%3E%3C/svg%3E");
  background-position: 0px 0px;
  animation: animatedBackground 15s linear infinite;
}

@keyframes animatedBackground {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 0px 1500px;
  }
}
