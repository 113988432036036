body {
  cursor: url('./assets/cursor.png') 0 0, auto;
}

.contact-links {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 0;
}

.contact-links a {
  flex: 1;
  text-align: center;
}

.particle {
  position: absolute;
  border-radius: 50%;
}

@for $i from 1 through 90 {
  @keyframes particle-animation-#{$i} {
    100% {
      transform: translate3d((random(90) * 1vw), (random(90) * 1vh), (random(100) * 1px));
    }
  }

  .particle:nth-child(#{$i}) {
    $hue: random(360);
    $color: hsl($hue, 70%, 50%);

    animation: particle-animation-#{$i} 40s infinite;
    $size: random(5) + 5 + px;
    opacity: random(100) / 100;
    height: $size;
    width: $size;
    animation-delay: -$i * .3s;
    transform: translate3d((random(90) * 1vw), (random(90) * 1vh), (random(100) * 1px));
    background: $color;
    box-shadow: 0 0 10px 8px $color;
  }
}

#particle-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  z-index: -1; 
}

.navbar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
  background-color: #fff;
}

.navbar-item {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  flex-grow: 1;
}

.navbar-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  flex-grow: 0;
}

.navbar-item img {
  max-width: 200px;
  height: auto;
  transition: transform 0.3s;
}

.navbar-logo img {
  max-width: 100px;
  height: auto;
  transition: transform 0.3s;
}

.navbar-item img:hover {
  transform: scale(1.1);
}

/* Mobile-friendly adjustments */
@media (max-width: 768px) {
  .navbar {
    // flex-direction: column;
    padding: 15px;
  }

  .navbar-item {
    margin-bottom: 3px;
  }

  .navbar-item img {
    max-width: 160%;
  }

  .navbar-logo {
    margin-bottom: 3px;
  }

  .navbar-logo img {
    max-width: 160%;
  }
}
